import React, { useContext } from "react";
import "../../assets/styles/Footer.css";
import { LanguageContext } from "../../context/LanguageContext";

function Footer() {
  const { language } = useContext(LanguageContext);

  const rightsReserved = {
    en: "2024 FJT SOLUTIONS, Inc. All rights reserved.",
    jp: "2024 FJT SOLUTIONS, Inc. All rights reserved.",
  };

  return (
    <footer className="App-footer">
      <div className="footer-container">
        <p>&copy; 2024 FJT SOLUTIONS, Inc. All rights reserved.</p>
        <p>Email: fujik@fjtsolutions.com</p>
      </div>
    </footer>
  );
}

export default Footer;
