import React, { useContext } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import contactImage from "../../assets/images/contact/contact-image.png"; // Contact page image
import "../../assets/styles/contact/ContactPage.css";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";

function ContactPage() {
  const location = useLocation();
  const { language } = useContext(LanguageContext);

  const home = {
    en: "Home",
    jp: "ホーム",
  };

  const contact = {
    en: "Contact",
    jp: "お問い合わせ",
  };

  const mainMessage = {
    en: "We are waiting for your inquiries by email address and LINE ID.",
    jp: "Eメールアドレス、LINE IDでのお問い合わせもお待ちしております。",
  };

  const email = {
    en: "E-mail Address",
    jp: "Eメールアドレス",
  };

  const line = {
    en: "Add LINE ID",
    jp: "LINE IDの追加",
  };

  return (
    <Container className="contact-page">
      <Row className="justify-content-center">
        <Col md={10}>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              {home[language]}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{contact[language]}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10} className="text-center">
          <h1 className="page-title">{contact[language]}</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <p>{mainMessage[language]}</p>
          <h2>{email[language]}</h2>
          <p>fujitak@fjtsolutions.com</p>
          <h2>{line[language]}</h2>
          <img
            src={contactImage}
            alt="Contact QR Code"
            className="img-fluid contact-image"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ContactPage;
