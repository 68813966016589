import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

const languages = {
  en: "English",
  jp: "日本語",
  // th: "ภาษาไทย",
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, languages }}>
      {children}
    </LanguageContext.Provider>
  );
};
