import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import fjttImage from "../../assets/images/news/fjtt/top.jpg";
import fjtImage from "../../assets/images/news/fjt/top.jpg";
import { LanguageContext } from "../../context/LanguageContext";
import "../../assets/styles/news/NewsDetails.css";
import { Link, useLocation } from "react-router-dom";

function NewsDetails() {
  const { language } = useContext(LanguageContext);
  const { id } = useParams();

  const home = {
    en: "Home",
    jp: "ホーム",
  };

  const newInfo = {
    en: "News",
    jp: "新着情報",
  };

  const newsItems = [
    {
      id: 1,
      title: {
        en: "We have established FJT SOLUTIONS, Inc.",
        jp: "FJTソリューションズ合同会社設立を設立いたしました。",
      },
      content: {
        en: `<p>We have established <strong>FJT SOLUTIONS, Inc.</strong> on January 4, 2022.</p>
            <p>We have received a wide range of requests and have developed systems for business efficiency, SNS, learning apps, chat apps, map apps, landing pages, and more.</p>
            <p>Our strength lies in our ability to offer comprehensive proposals utilizing a wide range of technologies from mobile apps to websites.</p>
            <p>We will provide the best proposals to meet your needs.</p>
            <p>We are very excited to be able to engage in a broader and deeper relationship with our customers through maintenance and operations now that we have become a corporation.</p>
            <p>Please feel free to contact us via the inquiry form, email address, or Line ID.</p>
            <p>We look forward to hearing from you.</p>`,
        jp: `<p>2022年1月4日をもちまして、FJTソリューションズ合同会社を設立いたしました。</p>
            <p>今まで、業務効率化システム、SNS、学習アプリ、チャットアプリ、マップアプリ、ランディングページ等幅広いご依頼をいただき、開発を行ってまいりました。</p>
            <p>当社の強みはモバイルアプリからWebサイトまで幅広い技術によってトータルでご提案できることです。</p>
            <p>お客様のご要望にあわせて、最適なご提案をさせていただきます。</p>
            <p>今回法人となったことでお客様と保守や運用等も含めた広く、深いお付き合いをできることを非常に楽しみにしております。</p>
            <p>どんなことでも結構です。まずはお問い合わせフォーム、Eメールアドレス、Line IDにてご連絡ください。</p>
            <p>お待ちしております。</p>`,
      },
      date: "2022-01-04",
      image: fjtImage,
    },
    {
      id: 2,
      title: {
        en: "We have established FJT SOLUTIONS (THAILAND) CO.,LTD.",
        jp: "FJT SOLUTIONS (THAILAND) CO.,LTD. を設立いたしました。",
      },
      content: {
        en: `<p>We have established <strong>FJT SOLUTIONS (THAILAND) CO.,LTD.</strong></p>
             <p>Our Japanese and Thai team members provide IT services, focusing on smartphone app development in Bangkok. If you are considering applications for smartphones, tablets, or the web in Thailand, please feel free to contact us.</p>
             <ul>
               <li>Promotion apps</li>
               <li>Map apps</li>
               <li>Internal business management apps</li>
               <li>Paperless apps</li>
             </ul>
             <p>We undertake the development of various apps.</p>
             <p>We also provide web page creation for Thai shops, infrastructure setup during company establishment (e.g., unique email addresses, server installation), and other IT-related consultations.</p>
             <p>The Thai contact point is handled by a Japanese person, so please rest assured. We can also work in cooperation with FJT Solutions LLC, which provides services in Japan, and the customer’s Japan contact point.</p>
             <p>For inquiries, please feel free to contact us via FJT SOLUTIONS (THAILAND) CO.,LTD.'s LINE account, email, or phone number.</p>`,
        jp: `<p>この度、タイ バンコクに関係会社の<strong>FJT SOLUTIONS (THAILAND) CO.,LTD.</strong> を設立いたしました。</p>
             <p>日本人とタイ人のメンバーが、バンコクにてスマホアプリ開発を中心にITサービスを提供しております。タイにてスマホやタブレット、Webで使用するアプリケーションをご検討でしたら是非お問い合わせ下さい。</p>
             <ul>
               <li>販促アプリ</li>
               <li>マップアプリ</li>
               <li>社内業務管理アプリ</li>
               <li>ペーパーレス化アプリ</li>
             </ul>
             <p>等、様々なアプリの開発を承ります。</p>
             <p>タイのお店のWebページ作成や、会社立ち上げ時のインフラの構築(独自のメールアドレス、サーバーの設置)等、ITに関わることであれば何でもご相談ください。</p>
             <p>タイの窓口は日本人が対応いたしますのでご安心ください。日本でサービスを提供するFJTソリューションズ合同会社や、お客様の日本窓口と連携した作業も可能です。</p>
             <p>お問合せはFJT SOLUTIONS (THAILAND) CO.,LTD. のLINEアカウント、メール、電話番号へお気軽にご連絡ください。</p>`,
      },
      date: "2023-06-20",
      image: fjttImage,
    },
  ];

  const news = newsItems.find((item) => item.id === parseInt(id));

  if (!news) {
    return <p>News article not found.</p>;
  }

  return (
    <Container className="news-details">
      <Row className="justify-content-center">
        <Col md={10}>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              {home[language]}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{newInfo[language]}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10} className="text-center">
          <img
            src={news.image}
            alt={news.title[language]}
            className="news-image"
          />
          <h1>{news.title[language]}</h1>
          <p>{news.date}</p>
          <div
            className="news-detail-content"
            dangerouslySetInnerHTML={{ __html: news.content[language] }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default NewsDetails;
